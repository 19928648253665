<template>
	<div>
		<div class='a-dropdown-date-picker'>
			<div class='a-dropdown-date-picker-day'>
				<a-select
					:label='!hideLabels ? $t("account.form.label.day") : null'
					placeholder='1'
					:options='days'
					v-model='day'
					required
					xsmall
					:errors='invalid'
				/>
			</div>
			<div class='a-dropdown-date-picker-month'>
				<a-select
					:label='!hideLabels ? $t("account.form.label.month") : null'
					:placeholder='$t("account.form.placeholder.january")'
					:options='months'
					v-model='month'
					required
					xsmall
					:errors='invalid'
				/>
			</div>
			<div class='a-dropdown-date-picker-year'>
				<a-select
					:label='!hideLabels ? $t("account.form.label.year") : null'
					placeholder='1990'
					:options='years'
					v-model='year'
					required
					xsmall
					:errors='invalid'
				/>
			</div>
		</div>
		<div class="app-input__details" v-if='!hideDetails'>
			<div class="error--text" v-if="errorMessage">{{ errorMessage }}</div>
			<div v-if="hint">{{ hint }}</div>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			date: null,
			day: null,
			month: null,
			year: null,
			days: [],
			months: [],
			years: []
		}),
		props: {
			value: String,
			hideLabels: {
				type: Boolean,
				default: false
			},
			invalid: [Boolean, Array],
			errorMessage: String,
			"hide-details": {
				type: Boolean,
				default: false
			},
			isFuture: {
				type: Boolean,
				default: false
			},
			hint: String
		},
		created() {
			this.createDays();
			this.createMonths();
			this.createYears();

			this.initValue();
		},
		methods: {
			initValue() {
				if(this.value) {
					this.day = this.$moment(this.value, 'YYYY-MM-DD').date();
					this.month = this.$moment(this.value, 'YYYY-MM-DD').month() + 1;
					this.year = this.$moment(this.value, 'YYYY-MM-DD').year();
				} else {
					this.day = null;
					this.month = null;
					this.year = null;
				}
			},
			createDays() {
				for (let i = 0; i < this.$moment(`${this.year ?? this.$moment().year()}-${this.month ?? this.$moment().month()}`, "YYYY-MM").daysInMonth(); i++) {
					this.days.push({id: i + 1, name: i + 1});
				}
			},
			createMonths() {
				for (let i = 0; i < 12; i++) {
					this.months.push({id: i + 1, name: this.$moment.months()[i]});
				}
			},
			createYears() {
				for (let i = 0; i < 100; i++) {
					let isFuture = this.isFuture ? this.$moment().year() + i : this.$moment().year() - i
					this.years.push({id: isFuture, name: isFuture});
				}
			},
			updateDate() {
				this.days = [];
				this.createDays();

				if(!(this.year && this.month && this.day)) return this.date = null;
				this.date = this.$moment(`${this.year}-${this.month}-${this.day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
			}
		},
		watch: {
			value() {
				this.initValue();
			},
			day() {
				this.updateDate();
			},
			month() {
				this.updateDate();
			},
			year() {
				this.updateDate();
			},
			date() {
				this.$emit('update-date', this.date)
			}
		}
	}
</script>

<style lang="scss">
	.a-dropdown-date-picker {
		display: flex;
		&-day {
			flex-shrink: 0;
			width: 65px;
			margin-right: 5px;
		}
		&-month {
			flex: 1 1 100%;
			margin-right: 5px;
		}
		&-year {
			flex-shrink: 0;
			width: 90px;
		}
	}
</style>
