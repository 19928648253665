<template>
	<div ref='a-card-details' @keydown.esc="closeDialog" tabindex='0'>
		<transition name="translate-right">
			<div class='a-card-details' v-if='value'>
				<div class='a-card-details-wrap' v-click-outside="closeDialog">
					<div class='a-card-details-header a-tourists-dialog-header'>
						<div class="row align-items-center">
							<div class="col">
								<p class='a-card-details-header-title'>
									<span class='a-card-details-header-title-arrow-back' v-if='edit' @click.stop='closeDialog'>
										<v-icon icon='arrow-prev' size='14' />
									</span>
									{{ edit ? $t("account.tourists.data_editing") : $t("account.buttons.add_tourist") }}
								</p>
							</div>
							<div class="col-auto" v-if='!edit'>
								<div class='a-card-details-header-button' @click.stop='closeDialog'>
									<v-icon icon='cross' size='17' />
								</div>
							</div>
						</div>
					</div>
					<ValidationObserver ref="form" v-slot="{ invalid }">
						<div class='a-card-details-content'>
							<ValidationProvider rules="required">
								<div class='a-form-tourists-group-radio'>
									<div>
										<v-radio
											id="man"
											name='gender'
											:label='$t("account.form.label.man")'
											value='man'
											v-model='form.gender'
										/>
									</div>
									<div>
										<v-radio
											id="woman"
											name='gender'
											:label='$t("account.form.label.woman")'
											value='woman'
											v-model='form.gender'
										/>
									</div>
								</div>
							</ValidationProvider>
							<div class="a-form-tourists-divider"></div>

							<template v-if='!edit'>
								<ValidationProvider rules="required">
									<div class='a-form-tourists-group-radio'>
										<div>
											<v-radio
												id="adult"
												name='type'
												:label='$t("account.form.label.adult")'
												value='adult'
												v-model='form.type'
												@input='updateBirthday'
											/>
										</div>
										<div>
											<v-radio
												id="children"
												name='type'
												:label='$t("account.form.label.children")'
												value='children'
												v-model='form.type'
												@input='updateBirthday'
											/>
											<div class='a-form-tourists-children' v-if='form.type === "children"'>
												<ValidationProvider rules="required">
													<p class='app-label'>{{$t("account.form.label.age")}}<sup>*</sup></p>
													<a-select
														:options='items_age'
														placeholder='<1'
														xxsmall
														v-model='age_children'
														@input='updateBirthdayChildren'
													/>
												</ValidationProvider>
											</div>
										</div>
									</div>
								</ValidationProvider>
								<div class="a-form-tourists-divider"></div>
							</template>

							<div class="a-form-tourists-form">
								<div class="row">
									<div class="col-6">
										<ValidationProvider :name='$t("account.form.label.first_name")' rules="required" v-slot='{errors}'>
											<v-text-field
												:label='$t("account.form.label.first_name")'
												name='name'
												v-model='form.name'
												required
												:hint='$t("account.form.hint.as_indicated_in_the_document")'
												:errorMessage='errors[0]'
											/>
										</ValidationProvider>
									</div>
									<div class="col-6">
										<ValidationProvider :name='$t("account.form.label.last_name")' rules="required" v-slot='{errors}'>
											<v-text-field
												:label='$t("account.form.label.last_name")'
												name='surname'
												v-model='form.surname'
												required
												:hint='$t("account.form.hint.as_indicated_in_the_document")'
												:errorMessage='errors[0]'
											/>
										</ValidationProvider>
									</div>
								</div>
								<div class="a-form-tourists-divider"></div>

								<template v-if='edit'>
									<div class="row">
										<div class="col-6">
											<p class='app-label'>{{$t("account.form.label.date_of_birth")}}:</p>
											<a-dropdown-date-picker
												v-model='form.birthday'
												@update-date='form.birthday = $event'
												hideLabels
												class="a-form-tourists-date"
												:errorMessage='errors[0]'
											/>
										</div>
									</div>
									<div class="a-form-tourists-divider"></div>
								</template>

								<div class="row">
									<div class="col-6">
										<ValidationProvider :name='$t("account.form.label.last_name")' :rules="form.email && form.email.length ? 'email' : ''" v-slot='{errors}'>
											<v-text-field
												label="Email"
												name='email'
												type='email'
												v-model='form.email'
												:errorMessage='errors[0]'
											/>
										</ValidationProvider>
									</div>
								</div>
								<div class="a-form-tourists-divider" style='margin-top: 16px'></div>

								<div class="row">
									<div class="col-6">
										<p class='app-label'>{{$t("account.form.label.phone_label")}}:</p>
										<div class="a-form-tourists-phone">
											<div class="a-form-tourists-phone-abbr">
												<v-text-field
													placeholder="+380"
													name='phone_abbr'
													type='text'
													v-model='phone_abbr'
													@input='updatePhone'
													required
													hideDetails
													v-mask='"+###"'
												/>
											</div>
											<div class="a-form-tourists-phone-full">
												<v-text-field
													name='phone_full'
													type='text'
													v-model='phone_full'
													@input='updatePhone'
													required
													hideDetails
													v-mask='"## ### ## ##"'
												/>
											</div>
										</div>
									</div>
								</div>
								<div class="a-form-tourists-divider"></div>

								<p class='app-label'>{{$t("account.form.label.avatar_color")}}:</p>
								<div class='a-form-tourists-color'>
									<div class='a-form-tourists-color-list'>
										<span class='a-form-tourists-color-list-item'
											v-for='(color, i) in colors' :key='i'
											:class='{"active": form.avatar_color == color}'
											:style='`background-color: ${color}`'
											@click='form.avatar_color = color'
										></span>
									</div>
								</div>

								<v-file-upload
									:title='$t("account.form.label.add_a_profile_photo")'
									accept='image/png,image/jpg,image/jpeg'
									:avatar='form.avatar'
									@update-files='form.avatar = $event'
									@remove-avatar='form.avatar = "", isRemoveAvatar = true'
								/>
							</div>
						</div>

						<div class='a-card-details-footer'>
							<div class='a-card-details-footer__divider'>
								<div class='divider' />
							</div>

							<div class="row align-items-center a-form-tourists-footer-row">
								<div class="col-auto">
									<div class='a-form-tourists-group-buttons'>
										<v-button xxsmall outline color='green' @click.stop='closeDialog'>{{$t("account.buttons.cancel")}}</v-button>
										<v-button xxsmall color='green white--text' class='a-form-tourists-button-add' @click='edit ? updateTourist() : addTourist()' :loading='loading_btn' :disabled='invalid'>{{edit ? $t("account.buttons.save") : $t("account.buttons.add_tourist")}}</v-button>
									</div>
								</div>
							</div>
						</div>
					</ValidationObserver>
				</div>
			</div>
		</transition>
		<div class="backdrop" v-if='value'></div>
	</div>
</template>

<script>
	import { mask } from 'vue-the-mask';
	import { disablePageScroll, enablePageScroll } from 'scroll-lock';
	import VFileUpload from '@/components/VFileUpload';
	import ADropdownDatePicker from '@/components/account/ADropdownDatePicker';
	import { items_age } from '@/helpers/items';
	import { addTourist, updateTourist, deletePhotoTourist } from '@/services/request';

	export default {
		data: () => ({
			items_age,
			loading: false,
			form: {
				gender: null,
				type: null,
				name: '',
				surname: '',
				phone: '',
				email: '',
				birthday: null,
				avatar_color: null
			},
			age_children: null,
			phone_abbr: '',
			phone_full: '',
			isRemoveAvatar: false,
			colors: ['#AFC4EC', '#90CBF0', '#91C9DD', '#92D5D5', '#95DAC0', '#9CDDA0', '#CBDE93', '#F5E087', '#F7C78E', '#F6AFA4', '#F49191', '#FAA8C3', '#DBB9EA', '#C3C0EF'],
			loading_btn: false,
			errors: {}
		}),
		directives: {
			mask: (el, binding) => {
				if (!binding.value) return;
				mask(el, binding);
			}
		},
		props: ['value', 'edit', 'card'],
		components: {
			VFileUpload,
			ADropdownDatePicker
		},
		methods: {
			addTourist() {
				this.request(addTourist, null, this.$t('account.messages.success.tourist_successfully_added'))
			},
			updateTourist() {
				this.request(updateTourist, {touristId: this.form.id}, this.$t('account.messages.success.tourist_successfully_update'))
			},
			async request(request, touristId, titleSuccess) {
				this.loading_btn = true;

				let formData = Object.assign({}, this.form);

				if(formData.avatar) {
					if(formData.avatar.length === 0) {
						delete formData.avatar
					}
					if(typeof formData.avatar !== 'object' && formData.avatar.includes('http')) {
						delete formData.avatar;
					}
				}

				if(this.isRemoveAvatar) {
					await deletePhotoTourist(this.form.id);
					this.isRemoveAvatar = false;
				}

				await request({
					data: formData,
					...touristId
				}).then(() => {
					this.$store.commit('notification/successMessage', {title: titleSuccess});
					this.$emit("update-items");
					this.$emit("update-item");
					this.closeDialog();
				}).catch(err => {
					this.errors = err.response.data?.errors ?? {};
				}).finally(() => this.loading_btn = false)
			},
			updateBirthday() {
				if(this.form.type === "adult") {
					this.form.birthday = this.$moment().subtract(30, 'years').format("YYYY-MM-DD");
				} else {
					this.form.birthday = null
				}
			},
			updateBirthdayChildren() {
				this.form.birthday = this.$moment().subtract(this.age_children, 'years').format("YYYY-MM-DD")
			},
			updatePhone() {
				this.form.phone = (this.phone_abbr + this.phone_full).replace(/[\s-]+/g, "");
			},
			closeDialog() {
				this.form = {
					gender: null,
					type: null,
					name: '',
					surname: '',
					phone: '',
					email: '',
					birthday: null,
					avatar_color: null
				};
				this.phone_abbr = '';
				this.phone_full = '';
				this.$emit('input', false);
			}
		},
		watch: {
			value() {
				if(this.value && this.card) {
					this.form = Object.assign(this.form, this.card);

					let formData = {};
					Object.keys(this.form)
						.filter(key => this.form[key] != null)
						.forEach((key) => formData = {...formData, [key]: this.form[key]});

					this.form = formData;

					if(this.form.phone) {
						let removePlus = this.form.phone.replace(/[\s+-]+/g, "");

						if(removePlus) {
							this.phone_abbr = `+${removePlus.slice(0, 3)}`;

							let phone = removePlus.slice(3);
							const match = phone.match(/^(\d{1,2})(\d{0,3})(\d{0,2})(\d{0,2})$/);
							if (match) {
								phone = `${match[1]} ${match[2] ? '' : ''}${match[2]}${match[3] ? '' : ''} ${match[3]}${match[4] ? '' : ''} ${match[4]}`;
							}
							this.phone_full = phone;
						}
					}

					if(!this.form.avatar_color) {
						this.form = {...this.form, avatar_color: null}
					}
				}
				setTimeout(() => {
					const hideEl = document.querySelector('.a-card-details-content')
					this.value ? disablePageScroll(hideEl) : enablePageScroll(hideEl)
				}, 150);
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-form-tourists {
		&-divider {
			@include minw($grid-breakpoints-xs) {
				margin-top: 35px;
				margin-bottom: 25px;
				border-bottom: 1px solid rgba($black, .1);
			}
		}
		&-group-radio {
			@include maxw($grid-breakpoints-xs - 1) {
				display: flex;
				flex-direction: row;
				& > div {
					&:first-child {
						margin-right: 40px;
						min-width: 95px;
					}
				}
			}
			.app-radio {
				margin-bottom: 25px;
			}
		}
		&-group-buttons {
			display: flex;

		}
		&-children {
			width: 65px;
		}
		&-form {
			.col-6 {
				@include minw($grid-breakpoints-xs) {
					flex: 0 0 calc(100% / 2);
					max-width: calc(100% / 2);
				}
			}
			.app-input {
				@include maxw($grid-breakpoints-xs - 1) {
					margin-bottom: 20px;
				}
			}
		}
		&-date {
			@include maxw($grid-breakpoints-xs - 1) {
				margin-bottom: 25px;
			}
		}
		&-phone {
			display: flex;
			align-items: center;
			&-abbr {
				flex-shrink: 0;
				width: 125px;
				margin-right: 5px;
			}
			&-full {
				flex: 1 1 100%;
			}
		}
		&-color {
			padding: 15px 0 30px;
			&-list {
				display: flex;
				flex-wrap: wrap;
				margin: -10px;
				@include minw($grid-breakpoints-xs) {
					margin: -15px;
				}
				&-item {
					width: 30px;
					height: 30px;
					margin: 10px;
					border-radius: 50%;
					cursor: pointer;
					transition: opacity $transition;
					@include minw($grid-breakpoints-xs) {
						width: 22px;
						height: 22px;
						margin: 15px;
					}
					&:hover {
						opacity: .7;
					}
					&:before {
						content: '';
						position: absolute;
						top: 2px;
						right: 2px;
						bottom: 2px;
						left: 2px;
						border: 2px solid $white;
						border-radius: 50%;
						transform: scale(0);
						transition: transform $transition;
					}
					&.active {
						cursor: default;
						opacity: 1;
						&:before {
							transform: scale(1);
						}
					}
				}
			}
		}
		&-footer-row {
			@include minw($grid-breakpoints-xs) {
				justify-content: flex-end;
			}
			@include maxw($grid-breakpoints-xs - 1) {
				flex-direction: column;
				justify-content: center;
			}
		}
		&-button-add {
			min-width: 120px;
		}
	}
</style>
